// import Alpine from '@alpinejs/csp';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';

window.Alpine = window.Alpine || Alpine;
window.Hooks = window.Hooks || {};

/**************************************
 * Plugins                            *
 **************************************/

// window.Alpine.plugin(…);
window.Alpine.plugin(intersect);
window.Alpine.plugin(persist);

/**************************************
 * Data                               *
 **************************************/

// Add global data declarations here. For ones only used in the odd place or
// two hook them up in a separate script in the alpine folder, and load them
// only on pages as needed using the `alpine:init` event.

// window.Alpine.data(…);
window.Alpine.data('darkMode', function () {
  return {
    darkModeRoot: {
      [':data-theme']() {
        const darkModeOn =
          this.$store.darkMode.on === null
            ? window.matchMedia('(prefers-color-scheme: dark)').matches
            : this.$store.darkMode.on;
        return darkModeOn ? 'dark' : 'light';
      },
    },

    darkModeSwitch: {
      ['@click.prevent']() {
        this.$store.darkMode.toggle();
      },
    },
  };
});

/**************************************
 * Stores                             *
 **************************************/
// Add global store declarations here. For ones only used in the odd place or
// two hook them up in a separate script in the alpine folder, and load them
// only on pages as needed using the `alpine:init` event.

// window.Alpine.store(…);
window.Alpine.store('darkMode', {
  init() {
    if (this.on === null || this.on === undefined) {
      this.on = window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  },

  on: window.Alpine.$persist(null).as('darkMode_on'),

  toggle() {
    this.on = !this.on;
  },
});

/**************************************
 * Initialise                         *
 **************************************/
if (!window.alpineStarted) {
  window.Alpine.start();
  window.alpineStarted = true;
}
